<template>
  <div class="bg-gray-800 rounded-lg overflow-hidden shadow-lg relative w-full max-w-xs h-80 mx-auto flex flex-col">
    <!-- Main image link -->
    <a :href="tool.links[0]?.url" class="block flex-grow h-40 overflow-hidden" :title="tool.name">
      <img :src="tool.image" alt="Tool Image" class="w-full h-full object-contain" />
    </a>
    <!-- Tool details and links -->
    <div class="p-4 flex flex-col justify-between flex-grow">
      <div>
        <h2 class="text-xl mb-2">{{ tool.name }}</h2>
        <p class="text-sm text-gray-400 mb-2">Version: {{ tool.version }}</p>
        <p class="text-sm text-gray-400 mb-2">{{ tool.description }}</p>
      </div>
      <div class="flex flex-wrap justify-start w-full space-x-2">
        <!-- Dynamic links -->
        <a
          v-for="(link, index) in tool.links"
          :key="index"
          :href="link.url"
          :title="link.name"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-grow"
        >
      <h2>{{ link.name }} 
      </h2>
        </a>
      </div>
    </div>
    <!-- Status indicator -->
    <div class="absolute top-2 right-2 flex items-center">
      <span class="relative flex h-3 w-3">
        <span :class="pingClasses" class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"></span>
        <span :class="statusClasses" class="relative inline-flex rounded-full h-3 w-3"></span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tool: {
      type: Object,
      required: true,
      validator: (value) => {
        // Validate that tool has the required properties and links is an array
        return (
          value &&
          typeof value.name === 'string' &&
          typeof value.version === 'string' &&
          typeof value.description === 'string' &&
          typeof value.image === 'string' &&
          Array.isArray(value.links) &&
          value.links.every(link => typeof link.url === 'string' && typeof link.name === 'string') &&
          (value.keywords ? Array.isArray(value.keywords) : true)
        );
      }
    }
  },
  computed: {
    statusClasses() {
      switch (this.tool.status) {
        case 'online':
          return 'bg-green-500';
        case 'offline':
          return 'bg-red-500';
        case 'work-in-progress':
          return 'bg-yellow-500';
        default:
          return 'bg-gray-500';
      }
    },
    pingClasses() {
      switch (this.tool.status) {
        case 'online':
          return 'bg-green-400';
        case 'offline':
          return 'bg-red-400';
        case 'work-in-progress':
          return 'bg-yellow-400';
        default:
          return 'bg-gray-400';
      }
    }
  },
  head() {
    return {
      title: this.tool.name,
      meta: [
        { name: 'description', content: this.tool.description },
        { name: 'keywords', content: this.tool.keywords.join(', ') }
      ]
    };
  }
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
