<template>
    <div class="flex justify-center">
      <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 max-w-screen-lg">
        <exburst-tool
          v-for="tool in tools"
          :key="tool.id"
          :tool="tool"
        ></exburst-tool>
      </div>
    </div>
  </template>
  
  <script>
  import ExburstTool from './ExburstTool.vue';
  
  export default {
    components: {
      ExburstTool
    },
    props: {
      tools: {
        type: Array,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Additional styles if needed */
  </style>
  