import { createRouter, createWebHistory } from 'vue-router';
// import ExBurst from '../home/exburst-main.vue'; // Adjust the path according to your project structure
// import DbsDeckbuilder from '../dbs/deckbuilder.vue'; // Adjust the path according to your project structure
import UaEnDeckbuilder from '../uaen/deckbuilder.vue';   // Adjust the path according to your project structure

const routes = [
  // {
  //   path: '/home',
  //   component: ExBurst
  // },
  // {
  //   path: '/dbs/',
  //   component: DbsDeckbuilder
  // },
  {
    path: '/ua/en',
    component: UaEnDeckbuilder
  },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;