<template>
  <div class="p-4 bg-gray-900 text-white min-h-screen">
    <h1 class="text-3xl mb-4">ExBurst - Tools</h1>
    <exburst-tool-list :tools="tools"></exburst-tool-list>
  </div>
</template>

<script>
import ExburstToolList from './ExburstToolList.vue';

export default {
  components: {
    ExburstToolList
  },
  data() {
    return {
      tools: [
  {
    id: 1,
    name: 'Union Arena Deckbuilder (Asia Ver.)',
    description: 'A comprehensive deckbuilding tool for the Union Arena Asia version.',
    image: 'https://exburst.dev/icon/logo_unionarena_white.png',
    status: 'online',
    version: 'v2.0',
    links: [
      { name: 'Go to Tool', url: 'ua' }
    ],
    keywords: ['Union Arena', 'Deckbuilder', 'Asia', 'TCG']
  },
  {
    id: 5,
    name: 'Union Arena Deckbuilder (US Ver.)',
    description: 'A comprehensive deckbuilding tool for the Union Arena US version.',
    image: 'https://exburst.dev/icon/logo_unionarena_white.png',
    status: 'online',
    version: 'v2.0',
    links: [
      { name: 'Go to Tool', url: 'ua/en' }
    ],
    keywords: ['Union Arena', 'Deckbuilder', 'US', 'TCG']
  },
  {
    id: 2,
    name: 'Dragon Ball Fusion World Deckbuilder',
    description: 'A powerful deckbuilding tool for Dragon Ball Fusion World.',
    image: 'https://exburst.dev/icon/logo_fw_b.png',
    status: 'online',
    version: 'v2.0',
    links: [
      { name: 'Go to Tool', url: 'dbs' }
    ],
    keywords: ['Dragon Ball', 'Deckbuilder', 'Fusion World', 'TCG']
  },
  {
    id: 8,
    name: 'Pokemon TCG Pocket Deckbuilder',
    description: 'A powerful deckbuilding tool for Pokemon TCG Pocket.',
    image: 'https://exburst.dev/icon/ptcgp_logo.png',
    status: 'online',
    version: 'v2.0',
    links: [
      { name: 'Go to Tool', url: 'pocket' }
    ],
    keywords: ['Lab', 'Deckbuilder', 'CCG', 'TCG']
  },
  {
    id: 6,
    name: 'Use my deckbuilder for your own game!',
    description: 'Contact us to create your own deckbuilder for any game you want!',
    image: 'https://exburst.dev/icon/playing-tcg-online.webp',
    status: 'online',
    version: '',
    links: [
      { name: 'Contact Me!', url: 'mailto:support@exburst.dev?subject=I%20would%20like%20to%20create%20my%20own%20deckbuilder...' }
    ],
    keywords: ['Deckbuilder', 'Custom Tool', 'Contact']
  },
  {
    id: 7,
    name: 'Make me Develop your own web tool!',
    description: 'Interested in developing a custom web tool? Contact us for more details!',
    image: 'https://exburst.dev/icon/requesttool.png',
    status: 'online',
    version: '',
    links: [
      { name: 'Contact Me!', url: 'mailto:support@exburst.dev?subject=I%20would%20like%20to%20create%20my%20own%20tool...' }
    ],
    keywords: ['Web Tool', 'Custom Development', 'Contact']
  },
  {
    id: 4,
    name: 'Templater',
    description: 'A versatile templating tool for creating and managing templates.',
    image: 'https://exburst.dev/icon/templater_logo.webp',
    status: 'offline',
    version: 'v0',
    links: [
      { name: '2025', url: '#' }
    ],
    keywords: ['Templater', 'Templates', 'Custom Templates']
  }
]

    };
  }
};
</script>

<style scoped>
/* Additional global styles if needed */
</style>
